.about {
	padding: 130px 0;
	display: flex;
	align-items: center;
	column-gap: 50px;

	img {
		border-radius: 5px;
		height: 450px;
		width: 400px;
		object-fit: cover;
		flex-shrink: 0;
	}

	&-content {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		span {
			color: #FFF;
			font-size: 16px;
		}
	}

	&-skills {
		margin: 20px 0;
		display: flex;
		flex-direction: column;
		row-gap: 20px;
	}
}

@media only screen and (width < 1280px) {
	.about {
		column-gap: 35px;
	
		img {
			height: 330px;
			width: 330px;
		}

		&-content {
			row-gap: 15px;

			span {
				font-size: 15px;
				line-height: 20px;
			}
		}

		&-skills {
			margin: 15px 0;
			row-gap: 15px;
		}
	}
}

@media only screen and (width < 1024px) {
	.about {
		padding: 100px 0;
		flex-direction: column;
		align-items: unset;
		row-gap: 30px;
	
		img {
			height: 220px;
			width: 220px;
			border-radius: 100%;
		}

		&-content {
			row-gap: 15px;

			span {
				font-size: 15px;
			}
		}

		&-skills {
			margin: 15px 0;
			row-gap: 15px;
		}
	}
}

@media only screen and (width < 768px) {
	.about {
		padding: 60px 0;
	
		img {
			height: 150px;
			width: 150px;
		}

		&-content {
			span {
				font-size: 14px;
			}
		}
	}
}

@media only screen and (width < 480px) {
	.about {
		padding: 40px 0;
	
		img {
			height: 120px;
			width: 120px;
		}

		&-content {
			row-gap: 10px;

			span {
				font-size: 12px;
			}
		}

		&-skills {
			margin: 10px 0;
			row-gap: 10px;
		}
	}
}