@import './reboot';
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');


* {
    font-family: "Source Code Pro", monospace;
    font-optical-sizing: auto;
}

svg {
    flex-shrink: 0;
}

body {
    background: #181b31;
}

strong {
    color: #FFF;
}

h1 {
    color: #FFF;
    font-size: 50px;
    line-height: 66px;
}

.pagebody {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
}

.main {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.container {
    margin: 0 auto;
    width: calc(100% - 200px);
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 60px;
    background: gold;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
    transition: .3s;
    &:hover {
        background: #FFF;
    }
}

@media only screen and (width < 1920px) {
    .container {
        width: calc(100% - 150px);
    }
    h1 {
        font-size: 42px;
        line-height: 52px;
    }
}

@media only screen and (width < 1280px) {
    .container {
        width: calc(100% - 100px);
    }
    h1 {
        font-size: 34px;
        line-height: 40px;
    }
}

@media only screen and (width < 1024px) {
    h1 {
        font-size: 26px;
        line-height: 34px;
    }
}

@media only screen and (width < 768px) {
    .container {
        width: calc(100% - 60px);
    }
    h1 {
        font-size: 24px;
        line-height: 32px;
    }
}

@media only screen and (width < 480px) {
    .container {
        width: calc(100% - 40px);
    }
    h1 {
        font-size: 20px;
        line-height: 30px;
    }
}