.header {
	padding: 0 100px;
	width: 100%;
}

.menu {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 40px;
	height: 80px;

	&__link {
		color: #FFF;
		font-size: 20px;
		line-height: 30px;
		text-decoration-color: transparent;
		text-decoration-thickness: 2px;
		text-underline-offset: 5px;
		transition: .3s;

		&.active {
			font-weight: 700;
			pointer-events: none;
			text-decoration-color: #FFF;
		}

		&:hover {
			text-decoration-color: #FFF;
		}
	}
}

@media only screen and (width < 1920px) {
	.header {
		padding: 0 75px;
	}
}

@media only screen and (width < 1280px) {
	.header {
		padding: 0 50px;
	}

	.menu {
		&__link {
			font-size: 18px;
		}
	}
}

@media only screen and (width < 768px) {
	.header {
		padding: 0 30px;
	}

	.menu {
		column-gap: 25px;

		&__link {
			font-size: 14px;
		}
	}
}

@media only screen and (width < 480px) {
	.header {
		padding: 0 20px;
	}

	.menu {
		column-gap: 20px;
		height: 60px;

		&__link {
			font-size: 13px;
		}
	}
}