.error404 {
    padding: 130px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;

    &-title {
        font-size: 70px;
        color: #FFF;
    }

    &-subtitle {
        font-size: 30px;
        color: #FFF;
    }

    .btn {
        margin-top: 40px;
    }
}

@media only screen and (width < 1024px) {
    .error404 {
    
        &-title {
            font-size: 60px;
        }

        &-subtitle {
            font-size: 28px;
        }
    }
}

@media only screen and (width < 480px) {
    .error404 {
    
        &-title {
            font-size: 50px;
        }

        &-subtitle {
            font-size: 24px;
        }

        .btn {
            width: 280px;
        }
    }
}