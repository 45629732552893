.copyright {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 5px;
    &-createdby {
        color: #FFF;
        font-size: 12px;
    }
    span {
        color: #FFF;
        font-size: 12px;
    }
}

@media only screen and (width < 768px) {
    .copyright {
        &-createdby {
            font-size: 10px;
        }
        span {
            font-size: 10px;
        }
    }
}

@media only screen and (width < 768px) {
    .copyright {
        &-createdby {
            font-size: 8px;
        }
        span {
            font-size: 8px;
        }
    }
}