.supportua {
    padding: 130px 0;
    display: flex;
    flex-direction: column;
    row-gap: 50px;

    h1 {
        max-width: 700px;
    }

    &-platform {
        display: flex;
        flex-direction: column;
        row-gap: 30px;

        span {
            color: #FFF;
            font-size: 20px;
        }
    }
}

@media only screen and (width < 1920px) {
    .supportua {
        h1 {
            max-width: 600px;
        }
    }
}

@media only screen and (width < 1280px) {
    .supportua {
        h1 {
            max-width: 400px;
        }
    }
}

@media only screen and (width < 1024px) {
    .supportua {
        h1 {
            max-width: 300px;
        }

        &-platform {
            span {
                font-size: 18px;
            }
        }
    }
}

@media only screen and (width < 480px) {
    .supportua {
        h1 {
            max-width: 250px;
        }

        &-platform {

            span {
                font-size: 14px;
            }

            .btn {
                width: 280px;
            }
        }
    }
}